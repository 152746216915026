.faq {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    .h2-text {
        margin-top: 2rem;
        margin-bottom: 48px;
    }

    .accordions-section > :not(:first-child) {
        margin-top: 2rem;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .accordions {
        border: 1px solid #F2F1ED;

        & > :not(:first-child) {
            margin-top: 3px;
        }
    }

    .accordion-question {
         display: flex;
         width: 100%;
        background-color: #F2F1ED;
        position: relative;
        font-size: 20px;
        transition: all 0.4s ease;
        cursor: pointer;
        padding: 8px 48px 8px 16px;
        font-weight: 700;
    }

    .accordion-question::after {
        content: url("/assets/icons/plus.svg");
        position: absolute;
        top: 50%;
        right: 16px;
        font-size:x-large;
        transform: translateY(-50%);
        transition: all 0.2s ease;
      }

      .accordion-answer {
        opacity: 0;
        max-height: 0;
        padding: 0 40px;
        overflow: hidden;
        transition: all 0.2s ease;
      }

      .expanded {
        border: 0 !important;

        .accordion-question::after {
            content: url("/assets/icons/minus.svg");
          }

        .accordion-answer {
            max-height: 9999px;
            opacity: 1;
            padding: 8px 40px;
        }
    }

    @media (max-width: 575.98px) {
        padding: 40px 0;

        h3 {
            padding: 0 16px;
        }
    }
}