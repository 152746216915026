// colours named using http://chir.ag/projects/name-that-color

$white: #fff;
$gray: #767679;
$gray2: #656567;
$light-gray: #e9e9e9;
$black: #000;
$smalt-blue: #587A8D;
$pampas: #F3F1ED;
$light-green: #587F56;

$primary: $smalt-blue;
$secondary: $black;
$primary-light: #526F84;

$hero-slate-black: #333133;
$hero-dusty-beige: #D3CCBD;
$hero-pale-olive: #B2BDAF;
$hero-light-gray: #EEEEEE;
$hero-slate-blue: $smalt-blue;
$hero-white: $white;

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-serif: 'Amiri', serif;

$h1-font-size: 3.75rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.125rem;

$headings-font-family: $font-family-serif;
$headings-font-weight: 400;
$headings-color: $black;

$transition-base: all .4s ease-in-out;

$grid-breakpoints: ( xs: 0, sm: 576px, smd: 768px, md: 992px, lg: 1200px, xl: 1600px );

$container-max-widths: ( md: 960px, lg: 1140px, xl: 1410px );

$section-spacing: 80px;

$border-radius: 0;

$btn-disabled-opacity: .45;

$input-bg: transparent;
$input-btn-padding-y: .75rem;
$input-btn-padding-x: 0;
$input-border-color: $gray;
$input-group-addon-bg: transparent;
$input-placeholder-color: #999;