.bav-corporate-b {
  .radios-button {
    label {
      display: inline-block;
    }
  }
  background: $body-bg;
  padding: 40px 0;
  position: relative;
  z-index: 1;

  #maincontent {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }

  label {
    display: block;
  }

  label:not(.form-check-label) {
    font-weight: 100;
  }

  .form-group label {
    font-weight: 700;
  }

  .row .col-sm-12 label {
    font-weight: 700;
  }

  p.healthcare-professionals {
    margin-bottom: 0px;
  }

  span.healthcare-professionals {
    font-weight: 100;
    font-size: 13px;
  }

  p.card-text {
    font-weight: 100;
  }

  .step2 {
    display: none;
  }

  h2 {
    background: #ececec;
    padding: 6px 12px;
  }

  fieldset:not(.form-group),
  .fieldset:not(.form-group) {
    border-bottom: solid 1px $gray;
  }

  legend {
    font-family: $font-family-serif;
    font-weight: normal;
    font-size: 2rem;
  }

  .form-control {
    border-color: $input-border-color;
    padding: 0.75rem;

    &:focus {
      border-color: $input-border-color;
    }
  }

  textarea {
    height: 12rem;
  }

  .checkbox,
  .radio {
    font-weight: normal;
  }

  .remove-companion,
  .alert {
    display: none;
  }

  .preselected-residence {
    label {
      display: block;

      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }
    }

    input {
      border: none;
      background: none;
      padding: 0;
    }
  }

  .btn-padding-top {
    padding-top: 25px;
  }

  .btn.btn-primary {
    text-transform: capitalize;
    min-width: 150px;
  }

  input[type='radio'] {
    margin-right: 25px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 992px) {
    #residences-map {
      display: none !important;
    }

    .remove-padding-x {
      padding: 3rem 0rem 3rem 0rem;
    }
  }

  @media screen and (max-width: 576px) {
    h3.m-1.res-card-title.card-title {
      font-size: 1.2rem;
    }

    h2.m-1.res-card-title.card-title {
      font-size: 1.2rem;
    }

    label.m-1.res-card-title.card-title {
      font-size: 1.2rem !important;
    }
  }
}
