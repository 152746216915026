button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0;
}

.call2action { //default
    background: rgba($primary, 1);
    border: 1px solid rgba($primary, 1);
    color: #FFFFFF;

    &:hover {
        color: $primary-light;
        background: $white;
    }

    svg {
        max-width: 20px;
        height: 20px;
    }
}

.btn {
    font-size: 0.875rem;
    text-transform: uppercase;
    min-width: 250px;
    padding: 0.75rem;

    svg {
        margin-right: .5em;
        margin-top: -.1em;
        vertical-align: middle;
    }

    &-primary {
        background: $primary;
        border-color: $primary;
        color: $light;

        &:hover {
            color: $light;
            border-color: darken($primary, 20%);
            background: darken($primary, 20%);
        }
    }

    &-secondary {
        background: $white;
        border-color: $primary-light;
        color: $primary-light;

        &:hover {
            color: $white;
            border-color: $primary-light;
            background: $primary-light;
        }
    }

    &-tertiary {
        background: $gray;
        border-color: $light;
        color: $light;

        &:hover {
            color: $primary;
            border-color: $light;
            background: $light;
        }
    }

    &-light {
        background: rgba($light, .7);
        border-color: transparent;
        color: $primary;

        &:hover {
            background: $light;
            color: $primary;
        }
    }

    &-transparent {
        background: $white;
        border-color: $primary-light;
        color: $primary-light;

        &:hover {
            background: $primary-light;
            color: $white;
        }
    }

    &:disabled {
        &:hover {
            background-color: inherit;
            color: inherit;
        }
    }

    &-help {
        min-width: 0;
        padding: 3px;

        svg {
            fill: $smalt-blue;
            height: 1.2rem;
            margin: 0;
            width: 1.2rem;
        }
    }
}


/* Organizing */
.input-group-append {
    .btn {
        border-bottom: solid 1px $input-border-color;
        min-width: 0;
    }
}
.hide-cta-in-mobile {
    @media (max-width: 768px) {
        display: none !important;
    }
}