svg {
  display: inline-block;
  fill: currentColor;
}

.bg-beige {
  background: #f3f1ed !important;
}

.sc-jss-editing-error {
  background: $body-bg;
}

.hidden {
  display: none !important;
}

.hidden-label {
  label {
    display: none !important;
  }
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}
