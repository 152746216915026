/*
    Code refactored - as the menu is pretty complex, it was getting hard to read. I divided it into three sections according to media-queries:
    Default styles, Big devices and Small devices.
*/

//Default styles

.not-absolute {
  .main-nav {
    position: relative !important;
  }
}

.main-nav {
  color: $black;
  position: absolute;
  width: 100%;
  z-index: $zindex-sticky;

  .logo {
    display: inline-block;
    position: relative;
    @include transition($transition-base);
    @include focus();

    svg,
    img {
      display: block;
      height: 60px;
      position: relative;
      width: 162px;
      @include transition($transition-base);
    }

    svg,
    svg path,
    svg polygon,
    svg rect {
      fill: $black;
    }
  }

  .svg-alt {
    display: none;
  }

  .menu {
    font-family: $font-family-serif;
    font-size: 1.125rem;

    ul {
      @include list-unstyled;
      margin: 0;
    }

    &-list {
      a {
        color: inherit;
        display: inline-block;
        line-height: 1.2;
        padding: 12px 20px;
        text-decoration: none;
        white-space: nowrap;
        @include transition($transition-base);
        @include focus();

        &:hover,
        &:focus {
          border-color: inherit;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  .menu-toprow {
    @include transition($transition-base);
    border-bottom: solid 1px #ccc;
    padding: 10px 0px;

    .container {
      display: block;
    }

    ul {
      @include list-unstyled;
      margin-left: -$grid-gutter-width/2;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 0;
    }

    &-list {
      display: flex;

      a {
        border-bottom: none;
        padding: 0px 10px;
        transition: all 0.4s;
        white-space: nowrap;
        color: inherit;
        display: inline-block;
        line-height: 1.2;
        padding: 0px 20px;
        text-decoration: none;
        white-space: nowrap;
        @include transition($transition-base);
        @include focus();

        &:hover,
        &:focus {
          border-color: inherit;
          outline: none;
        }
      }

      > li {
        display: inline-block;
        padding: 0px;
        position: relative;
        @include transition($transition-base);

        a {
          border-left: 1px solid $black;
        }

        &:hover > ul,
        > a:focus + ul {
          max-height: 400px;
          padding-bottom: 4px;
          padding-top: 4px;
        }
      }

      li.teaser {
        width: 100%;
        text-align: right;
      }

      li:first-child,
      li.teaser {
        a {
          border-left: none;
        }
      }
    }
  }

  .search {
    &-open {
      color: inherit;
      padding: 8px;
      @include focus();
    }

    &-box {
      align-items: center;
      background: rgba($white, 0.93);
      bottom: 100%;
      color: $black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      overflow: hidden;
      position: fixed;
      text-align: center;
      right: 0;
      top: 0;
      padding: 0 20px;
      z-index: $zindex-modal;
      @include transition($transition-base);

      &.show {
        bottom: 0;
      }

      .input-group {
        margin: 10px 0 40px;
        max-width: 450px;
      }
    }

    &-close {
      position: absolute;
      right: $grid-gutter-width/2;
      top: $grid-gutter-width/2;
    }

    &-title {
      font-family: $font-family-serif;
      font-size: 2.5rem;
      letter-spacing: 0.25em;
      line-height: 1.2;
      margin-bottom: 1em;
    }
  }

  .sticky & {
    position: fixed;
  }

  &.residence-nav {
    position: fixed;
    &.thank-you-nav-static {
      position: static;
    }

    .residencecta {
      &.contact {
        display: flex;
        align-items: center;
        column-gap: 4px;
      }
  
      .residence-phone-number {
        display: block;
      }
    }

    .menu-phone {
      border: none;
    }

    .div-as-h2-nav {
      color: $black;
    }
  }

  .div-as-h2-nav {
    font-size: 1.5rem;
    margin: 40px auto 20px;
    height: 28px;
    font-family: Amiri, serif;
  }
}

.residence-phone {
  display: none !important;
}

//Big devices
@include media-breakpoint-up(lg) {
  .main-nav {
    &:before {
      background: $white;
      bottom: 100%;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      @include transition($transition-base);
    }
    &-expand-lg {
      .navbar-collapse {
        display: block !important;
      }     
    }

    .container {
      align-items: flex-start;
      display: flex;
      position: relative;

      .call2action {
        display: none;
      }
    }

    .logo {
      display: block;
      margin-left: -$grid-gutter-width/2;
      padding: $grid-gutter-width;

      &:before {
        background: $gray;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        @include transition($transition-base);
      }
    }

    .menu {
      &-toggler {
        display: none;
      }

      &-list {
        display: block;
        text-align: right;

        a {
          border-bottom: solid 1px transparent;
          padding: 8px 0;
          transition: all 0.4s;
          white-space: nowrap;

          &:hover,
          &:focus,
          &.current {
            border-color: inherit;
            box-shadow: none;
          }
        }
        .menu-border {
          border: solid 1px;
          padding: 8px;
        }

        .submenu-toggler {
          display: none;
        }

        > li {
          display: inline-block;
          padding: 32px 15px 20px 20.25px;
          position: relative;
          @include transition($transition-base);

          > ul {
            background: $gray;
            box-shadow: 0 6px 6px rgba(0, 0, 0, 0.13);
            display: block;
            left: 50%;
            max-height: 0;
            overflow: hidden;
            padding: 0 10px;
            position: absolute;
            text-align: center;
            top: 100%;
            transform: translate(-50%, 0);
            @include transition($transition-base);

            li {
              padding: 15px 20px;
            }
          }

          &:hover > ul,
          > a:focus + ul {
            max-height: 400px;
            padding-bottom: 4px;
            padding-top: 4px;
          }
        }
      }
    }

    .search {
      padding: 30px 15px 10px;

      &-open,
      &-close {
        @include transition($transition-base);

        &:hover {
          opacity: 0.24;
        }
      }
    }

    .sticky & {
      color: $black;

      &:before {
        box-shadow: 0 2px 10px rgba(31, 31, 31, 0.12);
        bottom: 0;
      }

      .logo {
        padding: 20px 15px;

        &:before {
          bottom: 100%;
        }

        svg,
        svg path,
        svg polygon,
        svg rect {
          fill: $gray;
        }
      }

      .menu {
        &-list {
          > li {
            padding: 30px 15px;

            > ul {
              background: rgba($white, 0.93);
              box-shadow: 0 6px 6px rgba(31, 31, 31, 0.12);
            }
          }
        }
      }
    }

    &.residence-nav {
      .sticky & {
        color: $black;

        &:before {
          box-shadow: 0 2px 10px rgba(31, 31, 31, 0.12);
          bottom: unset;
        }
      }

      color: $black;
      background: rgba($white, 0.93);

      &:before {
        box-shadow: 0 2px 10px rgba(31, 31, 31, 0.12);
      }

      .menu-phone {
        svg {
          vertical-align: middle;
        }
      }

      .logo {
        padding: 20px 15px;

        &:before {
          bottom: 100%;
        }

        svg,
        svg path,
        svg polygon,
        svg rect {
          fill: $black;
        }
      }

      .menu {
        &-list {
          > li {
            padding: 30px 15px 30px 20.2px;

            > ul {
              background: rgba($white, 0.93);
              box-shadow: 0 6px 6px rgba(31, 31, 31, 0.12);
            }
          }
        }
      }

      .residencecta {
        display: inline-block;
        min-width: initial;
        color: $black;
        text-transform: initial;
        background: none;
        border: none;
        line-height: 1.2;
        padding: 38px 15px 10px;
        font-family: 'Amiri', serif;
        font-size: 1.125rem;
        margin-left: 2rem;

        svg {
          margin-right: 0;
        }

        &.book {
          svg {
            display: none;
          }
        }
      }
    }
  }
}

//Small devices
@include media-breakpoint-down(md) {
  .main-nav {
    background: $white;
    padding: 40px 0;

    @include media-breakpoint-down(xs) {
      padding: 20px 0;
    }

    .container {
      max-width: 90%;
    }

    .call2action {
      float: right;
      right: 120px;
      position: absolute;
      top: 45px;
      max-width: 250px;
      min-width: 145px;
      width: 40%;

      @include media-breakpoint-down(xs) {
        font-size: 0.7rem;
        right: 55px;
        top: 25px;
        min-width: 120px;
        width: 30%;
      }
    }

    .logo {
      display: block;
      margin: 0 15px;
      width: 162px;

      @include media-breakpoint-down(xs) {
        margin: 0;
        width: 140px;

        svg,
        img {
          height: 55px;
          width: 140px;
        }
      }
    }

    .menu {
      &-toggler {
        padding: 8px;
        position: absolute;
        right: $grid-gutter-width/2;
        top: 50px;

        @include media-breakpoint-down(xs) {
          top: 30px;
        }
      }

      &-list {
        text-align: left;
        padding: 30px 0;

        li + li {
          border-top: solid 1px rgba(0, 0, 0, 0.14);
        }

        > li {
          > a {
            font-size: 1.625rem;
          }

          &.has-submenu {
            flex-wrap: wrap;
            justify-content: space-between;

            .submenu-toggler {
              float: right;
              padding: 17px 20px;
            }
          }

          a.current {
            text-decoration: underline;
          }
        }

        ul {
          background: $white;
          color: $black;
          flex-grow: 1;
          margin-left: -($grid-gutter-width/2);
          margin-right: -($grid-gutter-width/2);
          padding: 0 $grid-gutter-width/2;

          li {
            margin-left: 20px;
          }
        }
      }
    }

    .menu-toprow {
      position: relative;

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    .search {
      &-open {
        position: absolute;
        top: 50px;
        left: auto;
        padding-top: 5px;
        right: 75px;

        @include media-breakpoint-down(xs) {
          top: 30px;
        }
      }
    }

    .sticky & {
      padding: 20px 0;

      .container {
        max-width: none;
      }

      .logo {
        display: block;
        height: 24px;
        margin: 0 8px;
        overflow: hidden;
        width: 102px;

        svg,
        img {
          height: auto;
          width: 102px;
        }
      }

      .menu-toggler {
        top: 15px;
      }

      .search {
        &-open {
          top: 15px;
        }
      }

      .call2action {
        top: 10px;
      }
    }

    &.residence-nav {
      padding: 0;
      background: #f2f2f2;
      color: $black;

      > .container {
        padding: 11px 15px;
        position: relative;

        @include media-breakpoint-down(xs) {
          position: initial;
        }

        .hamburger-inner,
        .plus-inner {
          background-color: $black;

          &:before,
          &:after {
            background-color: $black;
          }
        }

        .footer-content {
          h2 {
            color: $black;
          }
        }
      }

      .logo {
        svg,
        img {
          fill: $black;

          polygon,
          path,
          rect {
            fill: $black;
          }
        }

        @include media-breakpoint-down(xs) {
        }
      }

      .residencecta {
        background: none;
        color: $black;
        text-transform: initial;
        max-width: 100px;

        &.contact {
          border: none;
          right: 120px;

          svg.residence-phone-icon-outlined {
            margin-right: 0 !important;
            width: auto !important;
            height: 25px !important;
            g {
              fill: none !important;
              stroke: $black !important;
              stroke-width: 1.5 !important;
            }
          }

          .residence-phone-number {
            @include media-breakpoint-down(xs) {
              display: none;
            }
          }

          @include media-breakpoint-down(xs) {
            width: fit-content;
            min-width: fit-content;
            top: 9px !important;
            right: 126px;
          }
        }

        &.book {
          border: 1px solid $black;

          @include media-breakpoint-down(xs) {
            position: absolute;
            float: right;
            max-width: 250px;
            font-size: 0.7rem;
            right: 55px;
            min-width: 120px;
            width: 30%;
          }

          svg {
            display: normal;
          }
        }
      }

      .sticky & {
        .logo {
          height: 42px;
          width: 142px;
        }
      }
    }
  }

  body:has(.main-nav.menuOpen) {
    overflow: hidden;
  }

  .menuOpen {
    height: 100dvh !important;
    overflow-y: auto;
    top: 0;
  }

  .footer-content {
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1.5;

    p:first-line,
    .phone {
      font-weight: bold;
      font-size: 1rem;
    }

    .phone {
      display: inline-block;
      margin-top: 20px;
    }

    h2 {
      color: #eee;
      font-size: 1.5rem;
      margin: 40px auto 20px;
      opacity: 0.5;
    }
  }
  .residence-phone{
    display: block !important;
  }
}

