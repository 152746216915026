.guest-row {
    padding-top: 10px;
}

.guest-footer {
    padding-top: 10px;
    padding-bottom: 10px;
}

.guest-footer-text {
    margin-left: 10px;
    cursor: default;
}

.remove-companion-div {
    display: none;
}

.remove-button-img {
    width: 25px;
    padding-top: 16px;
    margin-left: 8px
}

.guest-footer-button img {
    width: 25px;
}

.remove-button-field {
    width: 2%
}

.guest-text-field {
    width: 49%;
    padding: 5px;
}

#add-button {
    text-decoration: none !important;
}

#guestValErr {
    margin-left: -10px;
}

#TbGuestValidator:focus {
    outline: none;
}

.validation-text {
    display: block;
    width: 60%;
    margin: 0 auto;
    padding: 0px 5px;
}

.guest-row {
    width: 60%;
    margin: 0 auto;
}
a.guest-footer-link {
    color: #587a8d;
    &:hover {
        color: #3b515e;
    }
}
.guest-footer-button {
    width: 60%;
    margin: 0 auto;
    padding: 0px 5px;

}

@media (max-width: 768px) {
    .validation-text {
        width: 100%;
    }

    .guest-row {
        width: 100%;
    }

    .guest-footer-button {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .guest-text-field {
        width: 100%;
    }
}

@media only screen and (max-width: 812px) {
    .remove-companion-div {
        display: inline;
        vertical-align: bottom;
        margin-left: 10px;
    }

    .remove-button-field {
        width: 100%;
        padding: 5px;
    }

    .remove-button-img {
        margin-left: 0px;
    }
}
