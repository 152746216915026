.year-select {
    width: 120px;
    font-weight: bold;
    color: $smalt-blue;
    border-color: transparent transparent transparent transparent;
}

.article-img {
    width: 100%;

    @media (max-width: 575.98px) {
        min-height: 0px;
    }

    @media (min-width: 576px) and (max-width: 1199.98px) {
        min-height: 500px;
    }

    @media (min-width: 1200px) {
        height: 26vw;
        min-height: 500px;
    }
}

@media (min-width: 1200px) {
    .first-media-card {
        width: 34% !important;
    }

    .second-media-card {
        width: 33% !important;
    }

    .third-media-card {
        width: 33% !important;
    }

}

.media-card-title {
    font-size: 39pt !important;
}