.pagination {
    margin-top: 5rem;
    margin-bottom: 0;
    padding-bottom: 2rem;

    .page-item {
        .page-link {
            border: 0px;

            &:hover {
                background-color: transparent;
            }
        }

        &.active {
            .page-link {
                background-color: white;
                color: $primary;
                border-bottom: 2px solid $primary;
            }
        }

        &.disabled {
            .page-link {
                color: darken($light, 20%);
            }
        }
    }
}
