.find-residence {
  background: #fff;
  position: relative;
  z-index: 4;

  img {
    height: 192px !important;
  }

  .btn-secondary,
  .btn-secondary:focus {
    background: $white;
    color: $primary;
  }
  .btn-secondary:hover {
    color: $light;
    border-color: $primary;
    background: $primary;
  }

  @media (max-width: 1599px) and (min-width: 1200px) {
    img {
      height: 160px !important;
    }

    .navigation-panel .main-content .content {
      padding: 10px !important;
    }

    #visit-res-btn {
      min-width: 200px !important;
    }

    .navigation-panel .main-content .content h2:after {
      width: 170px;
    }
  }

  @media (max-width: 1199px) and (min-width: 769px) {
    .container {
      max-width: 768px;
    }
  }

  h2 {
    background: #ececec;
    margin: 60px 0 10px;
    padding: 6px 10px;
  }

  h3 {
    margin: 0;
    padding: 10px 20px 10px 0;
    width: 50%;
    font-size: 1.17em;
    font-weight: bold;
  }

  a {
    color: inherit;
  }

  .call2action {
    @include media-breakpoint-down(md) {
      min-width: 180px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .btn {
    height: 50px;
  }

  .btn-secondary {
    @include media-breakpoint-down(md) {
      min-width: 180px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .checkbox {
    font-size: 0.875rem;
  }

  #memorycare {
    margin-right: 3px;
  }

  .field-validation-error {
    color: $danger;
    font-weight: normal;
    position: absolute;
    top: 100%;
  }

  .highlight {
    color: $light-green;
    display: block;
    font-weight: 700;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h2 + ul > li {
    display: flex;
    border-bottom: solid 1px #c6c6c6;
    padding: 10px;
  }

  h2 + ul > li ul {
    width: 50%;
  }

  h2 + ul > li li {
    padding: 10px 0;
  }

  .map {
    padding: 0 0 0;
    height: 80vh;

    &-small {
      height: 60vh;
      margin: 60px 0 0;
    }

    .map-image {
      max-width: 300px;
      max-height: 350px;
    }

    iframe {
      border: 0;
      width: calc(100vw - 20px);
      height: 100%;
    }
  }
}

.find-residence.filtered {
  h3 {
    width: 100%;
    padding: 15px 0;
  }
}

.innerContent {
  a {
    color: $smalt-blue;
  }
}

.find-residence.province {
  .map {
    padding: 60px 0 0;

    iframe {
      width: 100%;
      height: 450px;
    }
  }
}

.res-search-result-container {
  h2 {
    background: transparent;
    margin: 60px 0 10px;
    padding: 6px 10px;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  a {
    color: #f8f9fa;
  }
}

#residences-map img {
  height: unset !important;
}
