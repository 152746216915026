.cta-panel {
  background: #f7f7f7;
  padding: 100px 0;
  position: relative;
  text-align: center;
  z-index: 4;

  h2 {
    letter-spacing: 0.25em;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

@media (max-width: 768px) {
  h2 {
    letter-spacing: 0.15em;
  }
}

  p {
    margin: 30px auto 70px;
    max-width: 800px;
    width: 90%;
  }
}

.visit-us h2:after {
  border-bottom: solid 1px;
  content: '';
  display: block;
  margin-top: 40px;
  margin-right: auto;
  margin-bottom: 70px;
  margin-left: auto;
  max-width: 70%;
  width: 350px;
}
