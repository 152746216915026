.articles {
  background: $pampas;
  padding: 40px 0;
  position: relative;
  z-index: 1;

  a {
    color: inherit;
    font-size: 0.875rem;
    @include transition($transition-base);
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: -$grid-gutter-width/2;
    padding: 0;

    li {
      padding: $grid-gutter-width/2;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      @include media-breakpoint-between(sm, md) {
        width: 50%;
      }

      @include media-breakpoint-up(lg) {
        width: 25%;
      }
    }
  }

  &-pagination {
    //display: none;
    padding: 40px 0 0;
    text-align: center;
  }

  &-rollup {
    &-header {
      border-bottom: solid 1px;
      margin-bottom: 50px;
      padding: 0 0 15px;
      text-align: center;

      .link-goto {
        margin-top: 1rem;
        text-decoration: underline;
        .chevron-right {
          margin-left: 3px;
        }
      }

      @include media-breakpoint-up(md) {
        display: flex;
        text-align: left;

        h2 {
          flex-grow: 1;
        }
      }
    }

    ul {
      justify-content: center;
    }

    li {
      padding: $grid-gutter-width/2;
    }
  }

  &-page {
    background: $pampas;
    position: relative;
    z-index: 1;

    .breadcrumb {
      background: transparent;
      margin-bottom: 40px;
    }

    li {
      &.big,
      &:first-child,
      &.video,
      &.audio {
        @include media-breakpoint-between(sm, md) {
          width: 100%;
        }

        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }

      &.video,
      &.audio {
        .card-image {
          &:after {
            background: rgba(255, 255, 255, 0.4) url(/assets/icons/video-blue.svg) no-repeat center;
            background-size: 32px;
            border-radius: 50%;
            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.1);
            content: '';
            height: 120px;
            left: 50%;
            line-height: 1;
            margin: -60px 0 0 -60px;
            position: absolute;
            text-align: center;
            top: 50%;
            width: 120px;
          }
        }
      }

      &.audio .card-image:after {
        background-image: url('/assets/icons/audio.svg');
      }
    }
  }

  &-filters {
    border-bottom: solid 1px #b9b9ba;
    margin: 0 0 50px;
    padding: 5px 0 50px;
    text-align: center;

    button {
      margin: 10px;
      min-width: 190px;
    }

    @include media-breakpoint-down(md) {
      select {
        width: 80%;
      }
    }
  }

  &-empty {
    font-size: 1.25rem;
    text-align: center;
  }

  .loading-indicator {
    display: block;
    height: 64px;
    margin: 30px auto;
    width: 64px;
  }
}

#blog-filter-categories {
  padding-left: 0.75rem;
  background-position-x: calc(100% - 12px);
}

.article-share {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 47px;
}

.st-custom-button {
  display: flex;
  width: 42px;
  height: 42px;
  padding: 5px;
  margin: 0 2px 5px 2px;
  transition: all 0.2s ease-in-out;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    max-width: 22px;
    width: 22px;
    max-height: 22px;
    height: 22px;
    path,
    rect,
    polygon {
      fill: rgb(88, 122, 141) !important;
    }
  }
  &:hover {
    transform: translateY(-4px);
  }
}
@include media-breakpoint-down(sm) {
  .article-footer {
    .st-custom-button {
      margin: 0 5px 5px 2px;
    }
  }
}
