.residences {
  background: $pampas;
  padding: 40px 0;
  position: relative;
  z-index: 1;

  a {
    color: inherit;
    font-size: 0.875rem;
    @include transition($transition-base);
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: -$grid-gutter-width/2;
    padding: 0;

    li {
      padding: $grid-gutter-width/2;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      @include media-breakpoint-between(sm, md) {
        width: 50%;
      }

      @include media-breakpoint-up(lg) {
        width: 33%;
      }
    }
  }

  &-pagination {
    display: none;
    padding: 40px 0 0;
    text-align: center;
  }

  &-rollup {
    &-header {
      border-bottom: solid 1px;
      margin-bottom: 50px;
      padding: 0 0 15px;
      text-align: center;

      .link-goto {
        margin-top: 1rem;
        text-decoration: underline;
      }

      @include media-breakpoint-up(md) {
        display: flex;
        text-align: left;

        h2 {
          flex-grow: 1;
        }
      }
    }

    ul {
      justify-content: center;
    }

    li {
      padding: $grid-gutter-width/2;

      @include media-breakpoint-down(xs) {
        &:not(:first-child) {
          display: none;
        }
      }

      @include media-breakpoint-between(sm, md) {
        &:nth-child(n + 3) {
          display: none;
        }
      }
    }
  }

  &-page {
    background: $pampas;
    position: relative;
    z-index: 1;

    .breadcrumb {
      background: transparent;
      margin-bottom: 40px;
    }

    li {
      &.big,
      &:first-child,
      &.video,
      &.audio {
        @include media-breakpoint-between(sm, md) {
          width: 100%;
        }

        @include media-breakpoint-up(lg) {
          width: 50%;
        }
      }

      &.video,
      &.audio {
        .card-image {
          &:after {
            background: rgba(255, 255, 255, 0.4) url(/assets/icons/video-blue.svg) no-repeat center;
            background-size: 32px;
            border-radius: 50%;
            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.1);
            content: '';
            height: 120px;
            left: 50%;
            line-height: 1;
            margin: -60px 0 0 -60px;
            position: absolute;
            text-align: center;
            top: 50%;
            width: 120px;
          }
        }
      }

      &.audio .card-image:after {
        background-image: url(/assets/icons/audio.svg);
      }
    }
  }

  &-filters {
    border-bottom: solid 1px #b9b9ba;
    margin: 0 0 50px;
    padding: 5px 0 50px;
    text-align: center;

    button {
      margin: 10px;
      min-width: 190px;
    }

    @include media-breakpoint-down(md) {
      select {
        width: 80%;
      }
    }
  }

  &-empty {
    display: none;
    font-size: 1.25rem;
    text-align: center;
  }
}

.card-residence-name {
  font-size: 1.7rem;
  text-align: center;
}

.card-residence-btn {
  color: white !important;
  &:hover {
    color: $primary !important;
  }
}

.residence-search-panel {
  background-color: $pampas;
  padding-top: 20px;
}

.residence-lm-container {
  padding: 30px;
}

.card-residence-desc {
  border-top: solid 1px;
  padding-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
