.article-author {
  padding-top: 70px;

  .author-image-cropper {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
  }

  .author-img {
    height: 100%;
    max-height: 100px;
    width: auto;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .bio-panel {
    padding-left: 0px;
    padding-right: 0px;

    a {
      color: $smalt-blue;
      font-weight: bolder;
    }
  }
}

.author-img-panel {
  text-align: center;
}

@media (max-width: 576px) {
  .bio-panel {
    text-align: center;
  }

  .author-image-cropper {
    margin: auto;
  }
}

@media (min-width: 576px) {
  .author-img-panel {
    text-align: center;
  }

  .author-image-cropper {
    margin: auto;
  }
}

@media (min-width: 768px) {
  .author-img-panel {
    text-align: center;
  }

  .author-image-cropper {
    margin: auto;
  }
}

@media (max-width: 992px) {
  .bio-panel {
    padding-top: 10px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .author-img-panel {
    text-align: left;
  }

  .author-image-cropper {
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .article-author {
    margin-right: 95px;
    margin-left: 95px;
  }

  .author-img-panel {
    text-align: left;
  }

  .bio-panel {
    text-align: left;
  }
}
