@mixin hero-height($height, $min-height) {
  height: $height;
  min-height: $min-height;

  &:before,
  > picture img,
  > video {
    height: $height;
    min-height: $min-height;
    width: 100%;
  }
}

.hero {
  color: $white;
  position: relative;
  text-align: center;
  overflow: hidden;
  max-width: 100vw;

  &:before {
    background-image: linear-gradient(to bottom, #453f3f 0, rgba(90, 90, 90, 0) 45%);
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  > picture img,
  > video {
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top:0;
    width: auto;
    z-index: -2;

    @supports (object-fit: cover) {
      width: 100%;
    }
  }

  .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &-hat {
    color: $white;
    font-family: $font-family-serif;
    font-size: 2.5rem;
    letter-spacing: 0.13em;
    margin-bottom: 0;
  }

  .has-hat:after {
    content: '';
    display: block;
    width: 70px;
    margin: 25px auto;
    border: 1px solid $white;
    background: $white;
  }

  &-title {
    color: inherit;
    display: block;
    letter-spacing: 0.13em;
    margin: 0 auto;
    max-width: 850px;

    @include media-breakpoint-down(sm) {
      font-size: 2.25rem;
    }
  }

  &-ctas {
    bottom: 60px;
    left: 50%;
    max-width: 100%;
    padding: 0 50px;
    position: absolute;
    transform: translateX(-50%);
    width: 520px;

    .btn {
      display: block;
      margin-top: 10px;
      min-width: 0;
      width: 100%;
    }

    .full {
      min-width: 95%;
    }

    @include media-breakpoint-up(md) {
      bottom: 100px;
      display: flex;
      flex-wrap: wrap;
      font-size: 0;
      justify-content: space-around;

      .btn {
        margin-top: 20px;
        width: 190px;
      }

      .btn-block {
        flex-grow: 1;
      }
    }
  }

  &-scroll-down {
    bottom: 20px;
    color: inherit;
    display: block;
    left: 50%;
    position: absolute;
    cursor: pointer;
    transform: translateX(-50%);
    width: 40px;

    @include media-breakpoint-up(md) {
      bottom: 40px;

      &:hover {
        color: inherit;
        opacity: 0.24;
      }
    }
  }

  .hidden {
    display: none;
  }

  .hero-scroll-down {
    img,
    svg {
      vertical-align: initial;
    }
  }

  @include media-breakpoint-down(xs) {
    @include hero-height(500px, 0);
  }

  @include media-breakpoint-between(sm, md) {
    @include hero-height(720px, 0);
  }

  @include media-breakpoint-up(lg) {
    @include hero-height(40vw, 680px);
  }
}

h2.hero-subtitle {
  font-family: inherit;
  color: inherit;
}

.medium.hero {
  min-height: 360px;
  height: 21vw;

  .hero-subtitle {
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 2.3rem;
  }

  @include media-breakpoint-down(xs) {
    @include hero-height(380px, 0);

    .hero-subtitle {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    .hero-subtitle:before {
      margin: 3rem auto;
    }
  }

  @include media-breakpoint-down(md) {
    @include hero-height(500px, 0);

    .container {
      transform: translateY(-20%);
    }
  }

  @include media-breakpoint-up(lg) {
    @include hero-height(26vw, 500px);
  }
}

.x-small.hero {
  height: 140px;
  min-height: 0px;
}
.x-small.hero:before {
  background-image: none;
}

@media screen and (max-width: 576px) {
  .x-small.hero {
    height: 95px;
  }
}

.small.hero {
  height: 20vw;
  min-height: 400px;
  /* Makes text difficult to read
    &:before {
        background-image: linear-gradient(to bottom, rgba(90, 90, 90, 0.6) 15%, transparent 30%);
    }
    */
  @include media-breakpoint-down(xs) {
    @include hero-height(320px, 0);
  }

  @include media-breakpoint-down(md) {
    @include hero-height(400px, 0);

    .container {
      transform: translateY(-10%);
    }
  }

  @include media-breakpoint-up(lg) {
    @include hero-height(20vw, 400px);
  }
}

.video-hero {
  display: none;

  &--sm {
    @media (max-width: 575.98px) {
      display: block;
    }
  }

  &--md {
    @media (min-width: 576px) and (max-width: 1199.98px) {
      display: block;
    }
  }

  &--xl {
    @media (min-width: 1200px) {
      display: block;
    }
  }
}

.container .hero-hat {
  color: #fff;
  font-family: 'Amiri', serif;
  font-size: 2.5rem;
  margin-bottom: 0;
  font-size: 38px;
  letter-spacing: 8px;
  margin-top: 28px;
  line-height: 1;
}

.container .hero-title {
  color: inherit;
  display: block;
  margin: 0 auto;
  max-width: 850px;
  font-size: 77px;
  font-weight: 500;
  letter-spacing: 15.5px;
  padding-top: 0;
  position: relative;
  padding: 0;
  padding-bottom: 30px;
  margin-top: 4px;
  margin-bottom: 8px;
}

.container .has-hat::after {
  content: '';
  display: block;
  width: 130px;
  margin: 0 auto;
  border: 0px none !important;
  border-top: 1px solid #fff !important;
  background: transparent !important;
  position: relative;
  top: 9px;
  left: -6px;
}

.hero.medium .container .hero-subtitle {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 2px;
  font-weight: 600;
}

@media screen and (max-width: 810px) {
  .container .hero-title {
    color: inherit;
    display: block;
    margin: 0 auto;
    max-width: 850px;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: 15.5px;
    padding-top: 0;
    position: relative;
    padding: 0;
    padding-bottom: 30px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 575px) {
  .container .hero-hat {
    color: #fff;
    font-family: 'Amiri', serif;
    font-size: 2.5rem;
    margin-bottom: 0;
    font-size: 32px;
    letter-spacing: 3px;
    margin-top: 28px;
    line-height: 1;
  }

  .container .hero-title {
    color: inherit;
    display: block;
    margin: 0 auto;
    max-width: 850px;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 3px;
    padding-top: 0;
    position: relative;
    padding: 0;
    padding-bottom: 30px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}

.experience-editor {
  background: rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1200px) {
  .hidden-on-mobile {
    display: none;
  }
}
