.fullwidth-map {
  background: $body-bg;
  position: relative;
  z-index: 1;

  iframe {
    display: block;
    height: 48vw;
    max-height: 80vh;
    min-height: 420px;
    width: 100%;
  }

  .overlay {
    background: rgba(255, 255, 255, 0.45);
    color: $primary;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 45px;
    z-index: 9;
    @include transition($transition-base);

    .btn:focus,
    .btn:active {
      box-shadow: none;
    }

    .btn-secondary:focus {
      background: $white;
      border-color: $primary;
      color: $primary;
    }
    .btn-secondary:hover {
      color: $light;
      border-color: $primary;
      background: $primary;
    }

    .text-locked {
      display: block;
    }

    .text-unlocked {
      display: none;
    }

    &.active {
      background: transparent;
      bottom: auto;
      height: 50px;
      left: calc(50% - 180px);
      right: calc(50% - 180px);

      .text-locked {
        display: none;
      }

      .text-unlocked {
        display: block;
      }
    }
  }
}

.map-iframe {
  width: 800px;
  height: 600px;
}
