/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 21, 2018 */



@font-face {
    font-family: 'Amiri', Sans-Serif;
    src: url('amiri-bold-webfont.woff2') format('woff2'),
         url('amiri-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Amiri', Sans-Serif;
    src: url('amiri-bolditalic-webfont.woff2') format('woff2'),
         url('amiri-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}




@font-face {
    font-family: 'Amiri', Sans-Serif;
    src: url('amiri-italic-webfont.woff2') format('woff2'),
         url('amiri-italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}




@font-face {
    font-family: 'Amiri', Sans-Serif;
    src: url('amiri-regular-webfont.woff2') format('woff2'),
         url('amiri-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}