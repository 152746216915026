.h1-bav-forms {
    text-align: center;
    text-transform: none;
    font-family: Open Sans;
    letter-spacing: normal;
    background-color:white;
    margin-bottom: 0;
    padding-top: 100px;
}

@media (min-width: 768px) and (max-width: 1920px) {
    .h1-bav-forms {
        font-size: 24pt;
    }
}

@media (min-width: 320px) and (max-width: 640px) {
    .h1-bav-forms {
        font-size: 20pt;
    }
}