.cookie-banner {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 458px;
  background-color: #D9D9D9;
  text-align: center;
  z-index: 1000;
  padding-top: 9px;
  padding-bottom: 18px;

  h2 {
    margin: 0 0 10px;
    font-size: 1.5em;
    font-weight: bold;
  }

  p {
    margin: 0 0 20px;
    color: #000000;
    font-size: 10px;
    font-weight: 400;
    line-height: 12.1px;
    padding-left: 71px;
    padding-right: 71px;
    text-align: justify;
    word-wrap: break-word;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    padding-left: 69px;
    padding-right: 71px;
    gap: 10px;

    button {
      padding: 10px 20px;
      font-size: 1em;
      border: none;
      cursor: pointer;
      border-radius: 4px;

      &:first-of-type {
        background-color: #000;
        color: #fff;
        max-width: 118px;
        width: 90%;
        height: 2em;
        padding-top: 0px;
        padding-bottom: 0px;
      }

      &:last-of-type {
        font-size: 16px;
        font-weight: 700;
        line-height: 19.36px;
        text-align: center;
        color: #000;
        max-width: 133px;
        width: 90%;
        border: 2px solid #000;
        height: 2em;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .close-button {
    position: absolute;
    top: -6px;
    right: -6px;
    background: none;
    border: none;
    font-size: 34px;
    cursor: pointer;
    color: #000000;
    width: 44px;
    height: 44px; /* for accessibility rule */
  }
}

@include media-breakpoint-down(sm) {
  .cookie-banner {
    width: 317px;
    padding-top: 6px;
    padding-bottom: 10px;

    p {
      padding-left: 60px;;
      padding-right: 60px;
      margin-bottom: 6px;
    }

    .button-group {
      padding-left: 57px;
      padding-right: 57px;
      gap: 10px;

      button {
        &:first-of-type {
          max-width: 108px;
          width: 90%;
        }

        &:last-of-type {
          max-width: 92px;
          width: 90%;
        }
      }
    }

    .close-button {
      font-size: 24px;
    }
  }
}
