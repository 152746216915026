// Variables
$background-color: white;
$font-color: #666;
$border-color: #ccc;
$focus-border-color: #6c757d;
$label-font-size: 1rem;
$title-font-size: 2rem;
$paragraph-font-size: 1rem;
$checkbox-size: 20px;
$gap-small: 5px;
$gap-medium: 10px;
$gap-large: 15px;
$margin-bottom: 20px;
$textarea-height: 139px;

// Mixins
@mixin full-width-input($width: 100%) {
  width: $width;
  height: 48px;
  padding: 10px;
  border: 1px solid $border-color;
  margin-bottom: 10px;
  font-size: $label-font-size;
  &:focus {
    border-color: $focus-border-color;
    outline: none;
  }
}

// Git residence form container
.git-residence {
  max-width: 560px;
  margin: 0 auto;
  padding: 2px;
  background-color: $background-color;

.form-title{
    font-family: Amiri;
    font-size: 55px;
}
  // Title styling
  h1 {
    font-size: $title-font-size;
    font-weight: bold;
    margin-bottom: $gap-medium;
  }

  // Paragraph styling
  p {
    font-size: $paragraph-font-size;
    color: $font-color;
    margin-bottom: $margin-bottom;
  }

  // Label styling
  label {
    font-size: $label-font-size;
    font-weight: normal !important;
    display: block;
    margin-bottom: $gap-small;
  }

  // Group for first and last name inputs
  .name-group {
    display: flex;
    gap: $gap-large;

    .first-name,
    .last-name {
      width: 271px;
    }
  }

  // Input fields styling
  input[type="text"],
  input[type="tel"],
  input[type="email"] {
    @include full-width-input(557px);
  }

  // Radio button styling
  .radio-group label {
    font-size: 0.875rem;
    min-width: 145px;
    padding: 0.75rem;
    background: $background-color;
    border: 1px solid $border-color;
    color: black;
    text-align: center;
    font-weight: 100 !important;
    cursor: pointer;
  }

  .button-corp-radio-active {
    background: #587a8d !important;
    color: #fff !important;
    @media (max-width: 812px) {
      width: 100%;
    }
  }

  // Hide radio input
  .radio-group label input {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  // Textarea styling
  textarea {
    width: 560px;
    height: $textarea-height;
    padding: 10px;
    resize: none;
  }

  // Consent area styling
  .consent-area {
    display: flex;
    gap: $gap-medium;
    margin: 24px 0;

    input[type="checkbox"] {
      width: $checkbox-size;
      height: $checkbox-size;
      margin-right: 10px;
    }
  }

  // Button styling
  .btn-align {
    text-align: center;
  }

  .btn {
    height: 53px;
  }

  // Git Corporate Residence Form styling
  .git-cooperate-row {
    width: 557px !important;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  // Select form control
  select.form-control {
    width: 557px !important;
    height: 48px;
    background: transparent url('/assets/icons/down-arrow.svg') no-repeat right 0.75rem center !important;
    background-size: 17px 9px !important;
    appearance: none !important;
    position: relative;
    right: 21px;
  }

  // Selection card styling
  .res-selection-card {
    width: 558px;
    border: 1px solid #D9D9D9 !important;
    position: relative;
    right: 16px;
    background-color: $background-color;

    .card-body {
      height: 74px;
      padding: 0;

      h2 {
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 700;
        color: #000;
        text-align: left;
        background-color: white !important;
      }

      input[type="checkbox"] {
        width: $checkbox-size;
        height: $checkbox-size;
      }
    }
  }

  .col-2 {
    max-width: 66px !important;
    left: 9px;
  }

  .col-10 {
    padding: 10px 0;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    color: #000 !important;
  }

  .corporate-row-margin {
    margin-bottom: 24px !important;
  }

  .corporate-width {
    margin-bottom: 10px;
    margin-left: -5px;
  }

  .corporate-row-height {
    height: 24px;
  }

  .label-as-h4 {
    font-size: 16px !important;
    text-align: justify;
    margin-left: -4px;
  }
}

// Mobile responsiveness
@include media-breakpoint-down(xs) {
  .git-residence {
    max-width: 350px;

    h1 {
      font-size: 1.5rem;
    }
    
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    textarea,
    .name-group .first-name,
    .name-group .last-name,
    select.form-control,
    .res-selection-card,
    .git-cooperate-row,
    .btn {
      width: 350px !important;
    }

    .res-selection-card{
      height: 120px;
    }

    .card-text{
      text-wrap: balance;
    }

    .btn-padding {
      padding: 0;
    }

    .name-group {
      flex-direction: column;
    }
  }
}
