.video-panel {
  background: $body-bg;
  margin: 0 auto;

  iframe,
  video {
    min-width: 100%;
    max-width: 100%;
    width: 99vw;
    height: calc(100vw / 1.8);
    border: 0;
  }
}

.video-panel-experience-editor {
  p {
    text-align: center;
  }
  img {
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
