.event {
  background: $body-bg;
  margin: -150px auto 0;
  overflow: hidden;
  padding: 20px;
  position: relative;
  max-width: 1200px;
  width: 95vw;
  z-index: 4;

  .breadcrumb {
    margin-bottom: 40px;
    padding: 0;
  }

  h1 {
    font-size: 2.857em;
    letter-spacing: normal;
    text-transform: none;

    &:after {
      border-bottom: solid 1px;
      content: '';
      display: block;
      height: 0;
      margin: 30px auto;
      width: 185px;
    }
  }

  .lead {
    font-size: 1em;
    margin-bottom: 40px;
    text-align: left;
  }

  .add-calendar {
    margin: 30px 0;
  }

  &-body {
    margin: 0 auto;
    max-width: 890px;
    text-align: center;
  }

  &-info {
    padding: 30px 0;

    h2 {
      margin-top: 20px;
    }
  }

  @include media-breakpoint-up(sm) {
    > .container {
      padding: 20px 30px;
    }

    &-info {
      text-align: left;

      [class*='col'] {
        padding: 0 8%;

        + div {
          border-left: solid 1px $gray;
        }
      }
    }
  }
}
.input-event-attendees {
  display: none;
}
.event-first-name-other-guests {
  display: none;
}
.attendees-error {
  color: red;
  display: none;
}

.dropdown-toggle::after {
  display: none !important;
}
