.author-copy {
  @media only screen and (min-width: 1200px) {
    padding-left: 150px;
    padding-right: 150px;
  }

  @media (max-width: 576px) {
    .social-bottom {
      display: block;
    }

    .social-top {
      display: none;
    }
  }

  @media (min-width: 576px) {
    .social-bottom {
      display: none;
    }

    .social-top {
      display: block;
    }
  }

  .author-image-cropper {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    margin: auto;
  }

  .author-image {
    height: 100%;
    max-height: 200px;
    width: auto;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .bio-panel {
    padding-top: 15px;
  }
}
