body {
    color: $black;
}

p {
    margin: 0 0 .8em;
    line-height: 1.8;
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
    font-family: $font-family-base;
}

h1,
.h1 {
    letter-spacing: .25em;
    text-transform: uppercase;
}

.subtitle {
    font-size: 16px;
    font-weight: bolder;
    font-family: "Open Sans",sans-serif;
    color: $black;
}