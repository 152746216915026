.mosaic-gallery {
  background: $pampas;
  position: relative;
  z-index: 1;

  &-header {
    padding: 40px 0;
    text-align: center;

    .btn:not(:hover) {
      background: none;
    }
  }

  @include media-breakpoint-up(md) {
    h2 {
      display: inline-block;
      margin-right: 30px;
      vertical-align: middle;
    }
  }

  &-grid {
    a {
      background: $gray-400;
      box-shadow: inset 0 0 1px #fff;
      display: block;
      height: 33.33333vw;
      overflow: hidden;
      transition: all 0.4s;

      img {
        height: 100%;
        object-fit: cover;
        transition: all 0.4s;
        width: 100%;
      }

      &:hover img {
        transform: scale(1.2);
      }
    }

    .square-big {
      a {
        height: 66.66667vw;
      }
    }

    @include media-breakpoint-up(sm) {
      a {
        height: 16.66667vw;
      }

      .square-big {
        a {
          height: 33.3333vw;
        }
      }
    }
  }
}
