.text-resize {
    font-size: 1rem;
    text-align: right;

    button {
        background: none;
        border: none;
        color: $black;
        font-weight: 800;
        line-height: 1rem;
        margin: 0 .4rem;
    }

    &-big {
        font-size: 1.25rem;
    }

    &-bigger {
        font-size: 1.5rem;
    }
}
