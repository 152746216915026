//these variables comes from bootstrap
$body-bg: $white !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

//mixins imported from amica
@mixin title-line() {
  &:after {
    border-bottom: solid 1px;
    content: '';
    display: block;
    height: 1em;
    max-width: 70%;
    width: 200px;
  }
}

@mixin title-line-centered() {
  @include title-line();

  &:after {
    margin-left: auto;
    margin-right: auto;
  }
}

//mixins from bootstrap
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

.intro {
  background: $body-bg;
  padding: 40px 0;

  h1 {
    font-size: 2.5rem;
    letter-spacing: normal;
    text-transform: none;

    @include title-line();
  }

  h1.one-column {
    text-align: center;
    margin-top: 2rem;

    &:after {
      margin: 0 auto 3rem;
    }
  }

  .content-one-column {
    margin: 0 auto;
    max-width: 890px;
  }

  #white-paper-left-column .col-md-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  #white-paper-left-column .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  #white-paper-left-column h1.one-column {
    margin-top: 0rem;
  }

  #white-paper-mid-column {
    margin-left: 15px;
    margin-right: 15px;
  }

  #white-paper-right-column {
    padding-left: 0px;
    padding-right: 0px;
  }

  .cta {
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0;

    h1 {
      margin-bottom: 1em;

      @include title-line-centered();
    }
  }

  .address {
    text-align: center;

    img {
      fill: $primary;
      margin-right: 0.45rem;
    }

    a {
      color: $black;
    }
  }
}
