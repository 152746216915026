.breadcrumb {
  background: $body-bg;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: 0;

  a {
    color: inherit;
    text-decoration: underline;
  }

  .separator {
    margin: 0 20px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}
