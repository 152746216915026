.guest-row img {
  width: 25px;
}

.residence-opt-inf #guestValErr {
  margin-left: -12px;
}

.radios-button label {
  font-size: 0.875rem;
  min-width: 145px;
  padding: 0.75rem;
  background: #ffffff;
  border: 1px solid $gray2;
  color: $black;
  text-align: center;
  font-weight: 100 !important;
  cursor: pointer;
  margin: 5px;
}

.residence-opt-inf .checkbox-button {
  max-width: 776px;

  .field-validation-error {
    margin-left: 5px !important;
  }
}

.radios-button .button-corp-radio-active,
.checkbox-button .button-corp-radio-active {
  font-size: 0.875rem;
  min-width: 145px;
  padding: 0.75rem;
  background: #587a8d !important;
  border: 1px solid $gray2;
  color: #fff !important;
  text-align: center;
  font-weight: 100 !important;
  cursor: pointer;

  @media (max-width: 812px) {
    width: 100%;
  }
}

.radios-button label input {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.form-group h4 {
  padding-bottom: 25px;
}
.form-group .label-as-h4 {
  padding-bottom: 25px;
}

@media (max-width: 812px) {
  .residence-opt-inf .checkbox-button {
    max-width: 175px;
  }

  .residence-opt-inf textarea {
    width: 100% !important;
  }

  .radios-button label {
    width: 100%;
  }

  .anyone-joining-container label {
    min-width: 85.5px;
  }

  .checkbox-button label {
    width: 310px !important;
    margin: 5px 0 !important;
  }

  .residence-opt-inf .checkbox-button {
    .field-validation-error {
      margin-left: 0px;
    }
  }
}

.checkbox-button label {
  font-size: 0.875rem;
  width: 145px;
  padding: 0.75rem;
  background: #ffffff;
  border: 1px solid $gray2;
  color: $black;
  text-align: center;
  font-weight: 100 !important;
  cursor: pointer;
  margin: 5px;
}

.checkbox-corp-radio-active {
  font-size: 0.875rem;
  width: 140px;
  padding: 0.75rem;
  background: #587a8d !important;
  border: 1px solid $gray2;
  color: #fff !important;
  text-align: center;
  font-weight: 100 !important;
  cursor: pointer;
}

.checkbox-button label input {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

.residence-opt-inf .form-group {
  margin-bottom: 3rem;
}

.residence-opt-inf textarea {
  width: 57%;
}

.corporate-opt-inf {
  background: $body-bg;
  padding: 40px 0;
  position: relative;
  z-index: 1;

  .step2 {
    display: none;
  }

  h2 {
    background: #ececec;
    padding: 6px 12px;
  }

  fieldset,
  .fieldset {
    border-bottom: solid 1px $gray !important;
  }

  legend {
    font-family: $font-family-serif;
    font-weight: normal;
    font-size: 2rem;
  }

  .form-control {
    border-color: $input-border-color;
    padding: 0.75rem;

    &:focus {
      border-color: $input-border-color;
    }
  }

  textarea {
    height: 12rem;
  }

  .checkbox,
  .radio {
    font-weight: normal;
  }

  .remove-companion,
  .alert {
    display: none;
  }

  .preselected-residence {
    label {
      display: block;

      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }
    }

    input {
      border: none;
      background: none;
      padding: 0;
    }
  }
}
