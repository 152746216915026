.notification-banner-open {
  padding-top: 80px;
}
@media (min-width: 1200px) {
  .notification-banner-open {
    padding-top: 100px;
  }
}

/* General Banner Styles */
.notification-banner {
  background-color: $primary;
  color: $white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1100;
  font-family: $font-family-sans-serif;
  height: 80px;
  max-height: 80px;
  overflow: hidden;
}

.notification-banner p {
  margin: 0;
  max-width: 672px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  word-wrap: break-word;
}

.notification-banner a {
  color: $white;
  text-decoration: underline;
}

.notification-banner a:hover {
  color: $white;
}

.notification-content:hover {
  cursor: pointer;
}

/* Close Button Styles */
.notification-banner .close-btn {
  flex-shrink: 0;
  cursor: pointer;
  height: 24px;
  width: 24px;
  color: $white;
  background: none;
  border: none;
}

.notification-banner .close-btn:hover {
  color: #cceeff;
}

.notification-content {
  flex: 1 1 0%; /* Flex property to allow the content to grow */
  display: inline-flex; /* Display content as inline-flex */
  align-items: center; /* Align content to the center */
  justify-content: center; /* Justify content to the center */
  height: fit-content; /* Height will expand based on the content */
  color: $white;  /* Text color */
}

/* Desktop View */
@media (min-width: 1200px) {
  .notification-banner {
    height: 100px;
    max-height: 100px;
    padding: 20px 40px 20px 84px;
  }
  .notification-banner p {
    font-size: 18px;
    line-height: 30px;
  }
}
