.new-hero-container {
  width: 100%;
  position: relative;
  height: 861px;
  min-height: 861px;
  padding: 0;
  display: flex;

  .black-overlay {
    background: linear-gradient(to bottom, #453f3f 0, rgba(90, 90, 90, 0) 100%);
    height: 200px;
    min-height: 200px;
    width: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .content {
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  .img-container {
    position: relative;
    flex: 1;
    height: 100%;
    width: 50%;
  }

  .bg-image {
    object-fit: cover;
    height: 861px;
    min-height: 861px;
    object-position: center;
    position: fixed;
    top: 0;
    width: 50%;
  }

  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .text-content-wrapper {
    width: 50%;
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .text-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px;
  }

  .richText-wrapper {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
    text-align: center;
    color: #595a59;
    font-weight: 400;
    font-family: Amiri;
    font-size: 55px;

    h1,
    h2 {
      word-break: break-word;
    }
  }

  .paragraph {
    margin-top: 40px;
    color: #595a59;
    text-align: center;
    font-weight: 400;
    font-family: 'Open Sans';
    font-size: 16px;
  }

  .ctas-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 42px;
    margin-top: 50px;

    > * {
      min-width: 200px !important;
    }
  }

  .richTextHero-paragraph {
    color: #595a59;
    text-align: center;
    font-weight: 400;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    line-height: 169.781%;
  }

  .richTextHero-header-cap {
    color: #595a59;
    text-align: center;
    font-weight: 400;
    font-family: Amiri;
    font-size: 40px;
    font-style: italic;
    line-height: 105.781%;
    letter-spacing: 0.6px;
  }

  .richTextHero-header {
    color: #595a59;
    text-align: center;
    font-weight: 400;
    font-family: Amiri;
    font-size: 55px;
    font-style: normal;
    line-height: 105.781%;
    letter-spacing: 5.5px;
  }
}

.overlay-faded {
  .img-container,
  .bg-image {
    width: 60%;
  }

  .gradient-overlay {
    width: 101%;
  }

  .text-content-wrapper {
    width: 40%;
  }

  .gradient-to-right {
    right: 0;
    left: unset;
  }
}

.overlay-solid {
  .img-container,
  .bg-image,
  .text-content-wrapper {
    width: 50%;
  }
}

.image-on-right {
  .img-container {
    margin-left: auto;
  }

  .text-content-wrapper {
    left: 0;
  }
}

@media (max-width: 768px) {
  .new-hero-container {
    height: auto;
    min-height: auto;
    flex-direction: column;

    .black-overlay {
      background: linear-gradient(to bottom, #453f3f 0, rgba(90, 90, 90, 0) 60%);
    }

    .text-content-wrapper {
      position: relative;
      width: 100%;
    }

    .img-container,
    .bg-image {
      max-height: 500px;
      min-height: 400px;
      width: 100%;
    }

    .text-content {
      height: auto;
      width: 100%;
      padding: 20px;
    }

    .paragraph {
      margin-top: 30px;
    }

    .ctas-container {
      gap: 30px;
      margin-top: 30px;
    }

    .richTextHero-paragraph {
      font-size: 14px;
    }

    .richTextHero-header-cap {
      font-size: 28px;
      letter-spacing: 0.42px;
    }

    .richTextHero-header {
      font-size: 38.5px;
      letter-spacing: 3.85px;
    }
  }

  .overlay-faded {
    .gradient-overlay {
      width: 100%;
    }
  }
}
