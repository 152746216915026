.find-residence-widget {
  background-position: center;
  background-size: cover;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  z-index: 1;

  .container {
    background: $body-bg;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    padding: $section-spacing/2;
  }

  .h2 {
    display: block;

    &:after {
      background: $gray2;
      content: '';
      display: block;
      height: 1px;
      margin: 30px auto;
      width: 185px;
    }
  }

  .checkbox {
    display: block;
    text-align: left;
  }

  padding,
  .checkbox,
  .input-group {
    margin-bottom: 30px;
  }

  @include media-breakpoint-up(sm) {
    padding: $section-spacing 0;

    .container {
      padding: $section-spacing;
    }
  }
}
