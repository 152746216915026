.bav-residence {
    .stay-informed-text {
        padding-top: 40px;
        font-weight: 100;
        font-size: smaller;
    }

    .form-row .form-group .radio-list label {
        font-weight: 700 !important;
    }

    .radio-list label {
        width: 100%;
        margin-left: 0px;
    }

    .text-center {
        color: black;
    }

    label input {
        margin-right: 1.2em !important;
    }

    .btn {
        text-transform: capitalize !important;
        font-size: .875rem !important;
    }

    .btn-padding-top {
        padding-top: 25px;
    }

    .btn-transparent {
        background: 0 0;
        border-color: #767679 !important;
        color: black !important;
    }

    .btn-transparent:hover {
        background: #767679 !important;
        color: #f8f9fa !important;
    }
}