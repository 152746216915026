.differentiator {
  align-items: center;
  background-color: $body-bg;
  background-position: center;
  background-size: cover;
  display: flex;
  padding: 40px 0;
  position: relative;
  text-align: center;
  z-index: 4;

  h2,
  .description {
    margin: auto;
    max-width: 440px;
  }

  h2 {
    color: $black;
    font-family: 'amiriregular', serif;
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  h2:after {
    border-bottom: solid 1px;
    content: '';
    display: block;
    margin: 20px auto 0;
    width: 210px;
  }

  .panel-container {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 40px 0;
    position: relative;
    text-align: center;
    z-index: 4;
  }

  @media (max-width: 768px) {
    .panel-container {
      display: block;
      padding: 0 0;
    }
  }

  .description {
    line-height: 1.4;
    margin-bottom: 60px;
  }

  .content,
  .media,
  .media-show-in-mobile {
    flex-basis: 0;
    flex-grow: 1;
    padding: 20px;
    justify-content: center;
  }

  .media-show-in-mobile{    
    display: flex;
  }

  .media,
  .media-show-in-mobile {
    max-width: 80%;

    img,
    video {
      width: 90%;
      height: auto;
    }
  }
  /*&:nth-of-type(even) {
      flex-direction: row-reverse;
  }*/
  img.mobile-background {
    display: none;
  }

  @media (max-width: 768px) {
    background-image: none !important;
    display: block;
    border: solid 10px #f2f1ed;
    padding: 40px 0 0;
    min-height: 0;

    h2,
    .description {
      max-width: none;
    }

    &:nth-of-type(even) {
      background-position: bottom left;
    }

    .media {
      display: none;

      img {
        width: 100%;
      }
    }

    .media-show-in-mobile {
      max-width: 100%;

      img {
        width: 100%;
      }
    }

    img.mobile-background {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}
.differentiator {
  .panel-reverse {
    flex-direction: row-reverse;
  }
}

@media (max-width: 768px) {
  .small-diff {
    border: none;
    padding: 40px 0;
  }
}

.diff-iframe-video-container {
  position: relative;
  overflow: hidden;
  width: 90%;
  padding-top: 56.25%;
}

.diff-iframe-video {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
}

.negative-top-margin {
  margin-top: -80px !important;
}
