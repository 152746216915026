.thank-you-container {
  background: white;
  padding: 20px 0px;
}

div.thank-you {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.top-spacing-for-thank-you-message {
  padding-top: 15px;
}

div.bottom-spacing-for-thank-you-message {
  padding-bottom: 90px;
}

img.thank-you-checkmark {
  width: 52px;
  height: auto;
}

.thank-you-nav-static {
  background-color: $white !important;
  position: static !important;
}

.thank-you-nav {
  background-color: $white !important;
}

#thankyoumessage {
  font-size: 30px;
}

hr.thank-you-hr {
  border-top: 0.2em solid #d5d5d5;
}

@media screen and (max-width: 576px) {
  img.thank-you-checkmark {
    width: 36px;
    height: auto;
  }

  #thankyoumessage {
    font-size: 20px;
  }
}
