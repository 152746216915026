.articleExperienceEditor {
  margin-top: 0px !important;
}

.article {
  background: $body-bg;
  margin: -150px auto 0;
  // margin: 0 auto 0;
  overflow: hidden;
  padding: 20px;
  position: relative;
  max-width: 1200px;
  width: 95vw;
  z-index: 4;

  /*&:before {
        background-image: linear-gradient(to bottom, #453F3F 0, rgba(90, 90, 90, 0) 45%, rgba(76, 76, 76, 0.3) 100%);
        content: '';
    }

    > img,
    &:before {
        height: 400px;
        left: 0;
        object-fit: cover;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1;
    }

    > .container {
        position: relative;
        max-width: 1140px;
        width: 95vw;
        z-index: 4;
    }*/

  &-header {
    text-align: center;

    a {
      color: inherit;
      display: block;
      margin-bottom: 30px;
      text-decoration: underline;
    }
  }

  &-header,
  &-body,
  &-footer {
    margin: 0 auto;
    max-width: 890px;
  }

  &-tools {
    align-items: center;
    display: flex;
    margin-bottom: 50px;

    > * {
      flex-basis: 0;
      flex-grow: 1;
    }

    .breadcrumb {
      padding: 0;

      .container {
        padding: 0;
      }
    }
  }

  &-footer {
    margin-bottom: 50px;
    margin-top: 50px;
    min-height: 50px;
    position: relative;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &-body {
    img {
      display: block;
      height: auto;
      margin: 2em auto;
      max-width: 100%;
    }
  }

  &-share {
    text-align: center;
  }

  &-prev,
  &-next {
    line-height: 36px;
    position: absolute;
    z-index: 1;
  }

  &-prev {
    left: 0;

    svg {
      margin-right: 0.4em;
    }
  }

  &-next {
    right: 0;

    svg {
      margin-left: 0.4em;
    }
  }

  h1 {
    font-size: 2.857em;
    letter-spacing: normal;
    text-transform: none;

    &:after {
      border-bottom: solid 1px;
      content: '';
      display: block;
      height: 0;
      margin: 30px auto;
      width: 185px;
    }
  }

  .lead {
    font-size: 1em;
    text-align: left;
  }

  @include media-breakpoint-down(sm) {
    &-tools {
      .article-share {
        display: none;
      }
    }

    &-footer {
      .article-share {
        background: rgba($gray, 0.7);
        bottom: 0;
        left: 0;
        position: fixed;
        width: 100%;

        svg {
          path,
          rect,
          polygon {
            fill: $white !important;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    > .container {
      padding: 20px 30px;
    }
  }
}
