.floorplan {
  background: $body-bg;
  padding: 40px 0;
  position: relative;
  z-index: 1;

  img {
    height: auto;
    width: 100%;
  }

  h2 {
    border-bottom: solid 1px;
    font-size: 2rem;
    margin: 0 0 20px;
    padding: 0.6rem 0;
  }

  .btn {
    display: block;
    margin-bottom: 20px;
    width: 280px;
  }

  &:nth-of-type(2n + 1) .row {
    flex-direction: row-reverse;
  }
}
