.search-results {
  background-color: $white;

  .search-form {
    max-width: 100%;
    padding: 20px 0;
  }

  .result {
    max-width: 100%;
    padding: 20px 0;

    a:first-child {
      font-weight: bold;
    }
  }

  .search-input {
    padding-left: 10px;
  }

  .highlight-result {
    font-weight: bold;
  }

  .search-label {
    margin-bottom: 0 !important;
  }
}

.your-search {
  padding: 20px 0 10px;

  .terms {
    font-style: italic;
  }
}

.loading-indicator {
  display: block;
  height: 64px;
  margin: 20px auto;
  width: 64px;
}
