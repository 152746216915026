$body-bg: #fff;

.carousel {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .carousel-indicators {
    left: -50%;
    li {
      width: 15px;
      height: 15px;
      border: solid 2px #58798c;
      box-sizing: border-box;
      border-radius: 50%;
      position: relative;
      flex: 0 1 auto;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      background-color: transparent;
      cursor: pointer;
    }
    .active {
      background-color: #58798c;
    }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: '';
    }

    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: '';
    }
  }

  .carousel-caption {
    color: $black;
    left: 0;
    right: 0;
    h2,
    .description {
      margin: auto;
      max-width: 440px;
    }
    h2 {
      font-size: 24px;
      padding-top: 30px;
      line-height: 1.2;
      margin-bottom: 20px;
      font-family: 'amiriregular', serif;
    }
    h2::after {
      border-bottom: solid 1px;
      content: '';
      display: block;
      margin: 20px auto 0;
      width: 210px;
    }

    .description {
      line-height: 1.4;
      margin-bottom: 60px;
    }

    .ctaButtonCarousel {
      color: #587a8d;
      border-color: #587a8d;
      display: inline-block;
      border-radius: 0px;
      font-size: 0.875rem;
      text-transform: uppercase;
      min-width: 250px;
      padding: 0.75rem;
      margin-top: 10px;
    }
    .ctaButtonCarousel:hover,
    .ctaButtonCarousel:focus,
    .ctaButtonCarousel:active,
    .ctaButtonCarousel.active,
    .open > .dropdown-togglectaButtonCarousel {
      color: #fff;
      border-color: #587a8d;
      background-color: #587a8d;
      border-color: #285e8e; /*set the color you want here*/
    }
  }

  #rightsideCarousel {
    padding-left: 0px;
    padding-right: 0px;
  }

  .carousel-backgroundimage {
    max-height: 450px;
    width: 100%;
    height: 100%;
  }

  .background-carousel {
    justify-content: space-between;
  }
}

.carouselTestimonal {
  align-items: center;
  background-position: center;
  background-size: cover;
  position: relative;
  text-align: center;
  z-index: 4;

  .content,
  .media,
  .media-show-in-mobile {
    flex-basis: 0;
    flex-grow: 1;
    padding: 20px;
    justify-content: center;
  }

  .media,
  .media-show-in-mobile {
    max-width: 80%;

    img {
      width: 90%;
      height: auto;
    }
  }

  img.mobile-background {
    display: none;
  }

  @media (max-width: 768px) {
    background-image: none !important;
    display: block;
    border: solid 10px #f2f1ed;
    padding: 40px 0 0;
    min-height: 0;

    h2,
    .description {
      max-width: none;
    }

    &:nth-of-type(even) {
      background-position: bottom left;
    }

    .media {
      display: none;

      img {
        width: 100%;
      }
    }

    .media-show-in-mobile {
      max-width: 100%;

      img {
        width: 100%;
      }
    }

    img.mobile-background {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .small-diff {
    border: none;
    padding: 0px 0;
  }

  #rightsideCarousel {
    display: none;
  }

  #leftsideCarousel {
    width: 100% !important;
    padding-top: unset !important;
  }

  .background-carousel {
    background-image: none !important;
    background-color: #e6ebef !important;
    padding: 20px;
  }

  .carousel-indicators {
    left: 0% !important;
    // bottom: 14% !important;
  }

  #carousel-full {
    max-height: 500px !important;
  }
  .carousel .carousel-caption {
    h2 {
      padding-top: 0px;
    }
  }
}
