.footer {
  color: #fff;
  position: relative;
  z-index: 4;

  .wrapper {
    margin: 0 auto;
    max-width: 1365px;
    padding: 0 30px;
  }

  .footer-span-ee {
    color: #eee;
    font-size: 26px;
    margin-bottom: 20px;
    opacity: 0.5;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    color: #fff;
    font-size: 26px;
    margin-bottom: 20px;
    opacity: 0.7;
  }

  h3 {
    color: $white;
    font-size: 1rem;
    margin: 0;
  }

  #youtube img {
    width: 26px;
  }

  img {
    width: 18px;
    height: 18px;
    vertical-align: baseline;
  }

  svg {
    width: 18px;
    height: 18px;
    vertical-align: baseline;
  }

  .call2action img {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }

  .call2action svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }

  &-main {
    background: #747577;
    font-size: 14px;
    padding: 40px 0;

    .btn {
      display: block;
      margin: 20px;
      min-width: 180px;

      &:hover {
        text-decoration: none;
      }
    }

    .btn-tertiary:hover {
      color: $primary !important;
    }

    .btn.directions {
      display: none;
    }

    .wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    hr {
      border: none;
      border-right: solid 1px #000;
      height: 126px;
      margin: 0 40px;
      opacity: 0.14;
      padding: 0;
      width: 0;
    }

    .address {
      padding-right: 20px;
      min-width: 250px;

      p {
        line-height: 1.5;

        .phone {
          display: inline-block;
          margin-top: 7px;
        }
      }
    }

    .menu {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
      text-transform: uppercase;

      li {
        padding: 5px;
        width: 50%;
      }
    }

    .social {
      white-space: nowrap;

      a + a {
        margin-left: 20px;
      }
    }

    @media (max-width: 1150px) {
      .address {
        min-width: 160px;
      }

      hr {
        margin: 0 17px;
      }
    }

    @media (min-width: 1151px) and (max-width: 1300px) {
      hr {
        margin: 0 18px;
      }
    }
  }

  @media (max-width: 1068px) {
    &-main {
      text-align: center;

      .wrapper {
        display: block;
      }

      .ctas {
        .btn {
          display: inline-block;
        }

        @include media-breakpoint-down(xs) {
          .btn.get-in-touch {
            display: none;
          }
        }

        @include media-breakpoint-up(sm) {
          .btn.directions {
            display: none;
          }
        }
      }

      hr {
        border: none;
        border-bottom: solid 1px #000;
        height: 0;
        margin: 40px 0;
        width: 100%;
      }

      .address {
        margin-bottom: 40px;
        padding-right: 0;
      }

      .menu {
        display: block;

        li {
          padding: 10px 0;
          width: auto;
        }
      }

      .social #youtube img {
        width: 42px;
      }

      .social img {
        height: 32px;
        width: 32px;
      }

      .social svg {
        height: 32px;
        width: 32px;
      }
    }
  }

  &-legal {
    background: #6c6d70;
    font-size: 12px;
    padding: 10px 0;

    .wrapper {
      align-items: center;
      display: flex;

      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
    }

    .links,
    .copyright {
      padding: 10px 0;
    }

    .links {
      list-style: none;
      margin: 0;
      text-align: center;

      li {
        display: inline-block;
        padding: 5px 0;

        + li:before {
          content: '|';
          margin: 0 0.4em;
        }
      }
    }

    .copyright {
      flex-grow: 1;
      margin: 0;
      text-align: right;

      @include media-breakpoint-down(xs) {
        text-align: left;
      }
    }
  }
}
