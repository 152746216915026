.form {
  .div-iframe-video {
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .label-as-h4 {
    margin-bottom: 0.5rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400 !important;
    line-height: 1.2;
    color: $black;
    margin-top: 0;
    font-size: 1.5rem;
  }
  .section-msg-alert {
    text-align: center;
    height: 100px;
    margin-bottom: 80px;

    p {
      margin: 0px 0px !important;
      color: #595959;
    }
  }

  @media only screen and (max-width: 812px) {
    /* For mobile phones: */
    .section-msg-alert {
      height: 170px;
      margin-bottom: 0px;
    }
  }

  @media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 812px) {
    .section-msg-alert {
      height: 170px;
      margin-bottom: 0px;
      width: 50%;
      margin-left: 25%;
    }
  }

  &-section {
    background: $body-bg;
    padding: 40px 0;
    position: relative;
    z-index: 1;

    .modal & {
      padding: 0;
    }
  }

  &-actions {
    margin-top: 30px;
    text-align: center;

    .btn {
      margin: 0 15px 30px;
    }
  }

  fieldset:not(.form-group),
  .fieldset:not(.form-group) {
    border: none;
    // font-weight: bold;
    margin: 30px 0;
    padding: 0 0 30px;
  }

  fieldset.no-space {
    margin: 0;
    padding: 0;
  }

  legend,
  h2,
  h3 {
    font-family: $font-family-serif;
    font-weight: normal;
    font-size: 2rem;
  }

  h2,
  h3 {
    margin: 40px 0 20px;
  }

  label:not(.form-check-label) {
    font-weight: bold;
  }
  select.form-control {
    display: inline-block;
    width: 100%;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: transparent url('/assets/icons/chevron-down.svg') no-repeat right 0.75rem center;
    background-size: 17px 9px;
    appearance: none;
  }
  .form-control {
    border-color: $input-border-color;
    padding: 0.75rem;
    border-radius: 0;

    &:focus {
      border-color: $input-border-color;
    }

    &[readonly] {
      border: none;
      background: none;
      padding: 0;
    }
  }

  textarea {
    height: 12rem;
  }

  label.checkbox,
  .checkbox-list label {
    display: block;
    font-weight: normal;

    input {
      margin-right: 0.45em;
    }
  }

  .radio-list {
    .form-control[type='radio'] {
      width: auto;
      display: inline-block;
    }
    label:not(:empty) {
      display: inline-block;
      font-weight: 700;
      margin-right: 30px;

      input {
        margin-right: 0.45em;
      }
    }
  }

  .residence-selector,
  .companions {
    counter-reset: rows;

    > div {
      padding-left: 40px;
    }

    &-row {
      counter-increment: rows;
      position: relative;

      &::before {
        content: counter(rows) '.';
        left: 15px;
        line-height: 3rem;
        padding: 10px 0;
        position: absolute;
      }

      > div {
        padding-bottom: 10px;
        padding-top: 10px;
      }

      + .residence-selector-row > div,
      + .companions-row > div {
        border-top: solid 1px;
      }

      label {
        @include media-breakpoint-up(sm) {
          display: none;
        }
      }
    }

    label {
      margin: 0;
    }

    .custom-select {
      font-size: 0.875rem;
    }

    .add-row,
    .remove-row,
    .add-companion,
    .remove-companion {
      height: calc(3rem + 2px);
      min-width: 0;
      width: 100%;
    }

    .remove-row,
    .remove-companion {
      display: none;
    }
  }

  .field-validation-error,
  .invalid {
    color: #ED0131;
    font-weight: 400;
    font-size: 12px;
  }

  /* CSS for red border on invalid input */
.input-error {
  border: 1px solid red !important;
}

  .invalid-input {
    margin-bottom: 1rem;
  }
}

.tools-and-resources-section-page-id,
.tools-and-resources-current-page-id {
  display: none;
}

.bookavisit-getintouch-section-page-id,
.bookavisit-getintouch-current-page-id {
  display: none;
}

.form--loading {
  .form--button {
    display: none;
  }
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}
