$plus-padding-x: 20px;
$plus-padding-y: 12px;
$plus-layer-width: 20px;
$plus-layer-height: 2px;
$plus-layer-spacing: 6px;
$plus-layer-color: $white;
$plus-layer-border-radius: 2px;

.plus {
    padding: $plus-padding-y $plus-padding-x;
    display: inline-block;
    cursor: pointer;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:focus {
        outline: none;
    }

    &-box {
        width: $plus-layer-width;
        height: $plus-layer-width;
        display: block;
        position: relative;
        transition-property: all;
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &-inner {
        display: block;

        &:before,
        &::after {
            content: "";
            display: block;
            background-color: $plus-layer-color;
            border-radius: $plus-layer-border-radius;
            position: absolute;
            transition-property: all;
            transition-duration: 0.15s;
            transition-timing-function: ease-in-out;
            width: $plus-layer-width;
            height: $plus-layer-height;
            top: 50%;
            margin-top: $plus-layer-height / -2;
            opacity: 1;
        }

        &::after {
            transform: rotate(90deg);
        }
    }

    &:not(.collapsed) {
        .plus-inner {

            &::before {
                opacity: 0;
                transform: rotate(90deg);
            }

            &::after {
                transform: rotate(180deg);
            }
        }
    }
}