.form-control {
    border-color: transparent transparent $input-border-color transparent;

    &:focus {
        border-color: transparent transparent $input-border-color transparent;
        box-shadow: 0 0 0 0 $input-btn-focus-color;
    }

    &::placeholder {
        font-style: italic;
    }
}

.input-group-append {
    .btn {
        border-bottom: solid 1px $input-border-color;
        min-width: 0;
    }
}

.custom-select {
    background-image: url('/assets/icons/chevron-down.svg');
    background-size: 17px 9px;
}
