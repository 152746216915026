$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $black;
$hamburger-layer-border-radius: 2px;

.hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:focus {
        outline: none;
    }
}

.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}

/*
* Squeeze
*/
.hamburger {
    .hamburger-inner {
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
            transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        }

        &::after {
            transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &:not(.collapsed) {
        .hamburger-inner {
            transform: rotate(45deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.075s ease, opacity 0.075s 0.12s ease;
            }

            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}