.featured .card .card-image{
    height: 400px;
}

.card {
    background: $body-bg;
    border: none;
    height: 100%;
    position: relative;

    h3, h2 {
        font-family: 'amiriregular', serif;
    }

    h2{
        font-size: 2.0rem;
    }

    a {
        color: inherit;
        flex-grow: 1;
        font-size: 0.875rem;
        @include transition($transition-base);

        &:hover {
            text-decoration: none;

            h3 {
                color: $primary;
            }

            h2{
                color: $primary;
            }

            img {
                transform: scale(1.2);
            }
        }
    }

    &-image {
        position: relative;
        overflow: hidden;
        height: 260px;

        img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            @include transition($transition-base);
        }
    }

    &-body {
        padding: 20px;
    }

    &-footer {
        background: none;
        border-top: solid 1px $gray;
        margin: 0 20px;
        padding: 20px 0;

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        &.big-card {
            display: flex;
            flex-direction: row;

            .card-image {
                flex-basis: 50%;
                flex-shrink: 0;
            }

            .card-body {
                align-self: center;
                padding: 30px 6%;
            }
        }
    }
}
