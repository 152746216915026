.modal {
  padding: 0px !important;

  .inner-dialog {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &-footer {
    .scEmptyPlaceholder {
      width: 100%;
    }
  }

  &-backdrop.show {
    opacity: 0.95;
  }

  &-video {
    .close {
      color: $white;
      position: fixed;
      right: $grid-gutter-width;
      top: $grid-gutter-width;
    }
  }

  .page-editor & {
    background: $gray;
    display: block;
    opacity: 1;
    position: relative;

    &-dialog {
      transform: none !important;
    }
  }
}
