.pencil-banner {
  width: 100%;
  position: fixed;
  top: 100px;
  color: #fff;
  z-index: 999;

  @include media-breakpoint-down(md) {
    top: 60px;
  }

  .text-container {
    max-width: 1410px;
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;

    .description {
      text-align: center;
      font-family: 'Amiri', serif;
      font-size: 15.4px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.7px;
      letter-spacing: 1.85px;

      * {
        margin-bottom: 0;
      }
      a {
        text-decoration: underline;
        text-underline-offset: 4px;
      }

      @include media-breakpoint-down(md) {
        font-size: 15.1px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }

    @include media-breakpoint-down(md) {
      width: calc(100% - 70px);
      margin: auto;
      padding-bottom: 18px;
      padding-top: 18px;
    }
  }

  .close-btn {
    position: absolute;
    right: 21px;
    top: calc(50% - 6px);
    cursor: pointer;
  }

  .richTextPencil-description {
    color: #fff;
    text-align: center;
    font-family: Amiri;
    font-size: 15.429px;
    font-style: normal;
    font-weight: 400;
    line-height: 115.281%;
    letter-spacing: 1.851px;
  }

  @media (max-width: 768px) {
    .richTextPencil-description {
      color: #fff;
      text-align: center;
      font-family: 'Amiri';
      font-size: 16.901px;
      font-style: normal;
      font-weight: 400;
      line-height: 131.281%;
      letter-spacing: 2.028px;
    }
  }
}
