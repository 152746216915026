@mixin focus() {
    &:focus {
        box-shadow: 0 0 0 4px $input-btn-focus-color;
        outline: none;
    }
}

@mixin title-line() {
    &:after {
        border-bottom: solid 1px;
        content: '';
        display: block;
        height: 1em;
        max-width: 70%;
        width: 200px;
    }
}

@mixin title-line-centered() {
    @include title-line();

    &:after {
        margin-left: auto;
        margin-right: auto;
    }
}
