.call2action {
  img,
  svg {
    max-width: 20px;
    height: 20px;
    margin-right: 0.5em;
    margin-top: -0.1em;
    vertical-align: middle;
  }
}
