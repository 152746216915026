.navigation-panel {
  background: #f2f1ed;

  .container {
    @media (min-width: 1600px) {
      max-width: 1300px;
    }
  }

  .res-search-result-container {
    @media (min-width: 1600px) {
      max-width: 1410px !important;
    }
  }

  .container,
  .res-search-result-container {
    .differentiator {
      min-height: 22vw;
      padding: 40px 0;
      margin: 60px 0;

      @media (max-width: 991px) {
        display: block;
        margin: 0;

        .media {
          display: none;
        }
      }
    }

    .headline {
      width: 100%;

      h2 {
        padding: 6px 10px;
        margin: 0px;
      }
    }

    .nav-headline-left {
      text-align: left;
    }

    .nav-headline-center {
      text-align: center;
    }

    .nav-headline-right {
      text-align: right;
    }
  }

  .main-content {
    background-image: none !important;
    background-color: #fff;
    position: relative;
    padding: 0;
    min-height: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    align-items: center;
    text-align: center;
    z-index: 4;

    .content,
    .media {
      padding: 20px;
      justify-content: center;
      width: 100%;
    }

    .content {
      h2,
      p {
        margin: auto;
        max-width: 440px;

        @media (max-width: 1199px) {
          max-width: 90%;
        }
      }

      h2 {
        color: $black;
        font-family: 'amiriregular', serif;
        font-size: 2rem;
        line-height: 1.2;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      h2:after {
        border-bottom: solid 1px;
        content: '';
        display: block;
        margin: 20px auto 0;
        width: 210px;
      }

      p {
        line-height: 1.4;
        font-size: 0.9rem;
      }
    }

    .media,
    .residence-media {
      padding: 0px;
      width: 100%;
      max-width: 100%;

      img {
        width: 100%;
        height: 240px;
        object-fit: cover;
      }
    }

    .btn {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 768px) {
      .residence-media img {
        display: none;
      }
    }

    @media (max-width: 768px) {
      display: block;
    }
  }
}

.scEmptyPlaceholder {
  min-width: 100px !important;
}
