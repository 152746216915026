.modal-video {
  .modal-sm,
  .modal-md,
  .modal-lg {
    max-width: 60% !important;
    margin: auto !important;
    padding: 0 !important;

    @media (max-width: 768px) and (orientation: portrait) {
      max-width: 100% !important;
    }
  }

  .modal-content {
    background-color: transparent !important;
  }
}

.modal-dialog {
  min-height: 100% !important;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.ee-video-container { 
  video,img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}