.white-paper-left-column .col-md-9 {
  flex: 0 0 100%;
  max-width: 100%;
}

.white-paper-right-column .col-md-9 {
  flex: 0 0 100%;
  max-width: 100%;
}

.white-paper-left-column .container {
  padding-left: 0px;
  padding-right: 0px;
}

.white-paper-left-column h1.one-column {
  margin-top: 0rem;
}

@media (min-width: 992px) {
  .white-paper-left-column {
    flex: 0 0 60%;
    max-width: 60%;
    padding-right: 50px;
  }

  .white-paper-right-column {
    flex: 0 0 40%;
    max-width: 40%;
    padding-left: 50px;
  }
}

@media (max-width: 991px) {
  .white-paper-left-column .intro {
    margin: 15px;
  }

  .white-paper-right-column .intro {
    margin: 15px;
  }
}
