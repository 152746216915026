.res-selection-card {
    background-color: $light-gray;
}

.container h4 {
    font-size: 1.5rem;
}

.single-card {
    background-color: $light-gray;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.res-card-column {
    margin-top:10px;
}

.container .row .res-card-column label {
    margin-bottom: 0px;
}

.res-select-map {
    height: 100%;
    width:100%;
    min-height: 332px;

}

.res-selection-card .card-body h3 {
    font-family: "Open Sans",sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
}

.res-selection-card .card-body h2 {
    font-family: "Open Sans",sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #e9e9e9 !important;
    padding: 0px 0px !important;
}

.res-card-title {
    font-size: 1.7rem;
}

.res-selection-card .card-body input[type=checkbox] {
    width: 2rem;
    height: 2rem;
}

@media screen and (max-width: 768px) {
    .single-card {
        width: 100%;
    }
}
