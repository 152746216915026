.featured-container {
  background: $pampas;
  margin-right: 0px;
  margin-left: 0px;
}

.featured-content {
  padding: 20px 10px 20px 20px;
}

.featured-content.full {
  padding: 20px;
}

.featured-content h2 {
  font-size: 1.7rem;
}

.featured-content div.description {
  font-size: 0.9rem;
}

.featured-content a {
  margin-top: 15px;
  font-size: 0.8rem;
}

.featured-image {
  padding: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
